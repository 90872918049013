@import "./variables.scss";
@import "./transitions.scss";
@import "./home.scss";

.fancy-foodtag {
  // margin-right: 5px;
  // background-color: red;
  color: #fff;
  padding: 5px;
  font-size: 1.5rem;
  border-radius: 50%;
}
.foodtag {
  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid transparent;
  margin: 0 5px;
  // border-radius: 50%;
  & > span {
    border-radius: 10px;
    position: absolute;
  }
}
.timer {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #fff;
  color: #fff;
  z-index: 1;
  border-radius: 2rem;
  width: 2rem;
  height: 2rem;
  // box-shadow: 0 0 0 1px rgba($color: #000, $alpha: 0.1);
  border: 1px solid transparent;
  // padding: 2px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  // animation: lightUp 1s 0.1s infinite;
  border: 2px solid #ffffff95;
  transition: 0.2s;
  // box-shadow: 0 0 #fff;
}
.mask {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: fadein 0.2s;
  background-color: rgba($color: #fff, $alpha: 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}
.open-up {
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.15), 0 0 0 rgba(16, 22, 26, 0),
    0 0 0 rgba(16, 22, 26, 0);
  padding: 4px 8px;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 43px;
  border-radius: 4px;
}
.open-up > p {
  margin: 0;
  line-height: 2.5;
  font-size: 12px;
  font-weight: 500;
}
.open {
  display: inline;
}
.open:hover .open-up {
  display: block;
}


.card-pay{    
  width: 190px;
  margin: 20px auto;
  padding: 15px;
  text-align: center;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  border: 0 !important;
  box-shadow: 1px 1px 4px 0px rgb(16  22 26 / 20%);
  background-color: white !important;
}
.card-pay > div >p{
  color:gray;
  margin-top: 15px;
}
.d-flex {
  display: flex;
}

.h3-adj{
  font-size: 20px;
  line-height: 0;
  margin: 15px 0;
}


.card-head {
  text-align: center;
  color: #347afa;
  font-size: 18px;
}
.alice-carousel__dots{
  display: none !important;
}
.alice-carousel__prev-btn,.alice-carousel__next-btn{
  position: absolute;
  text-align: left !important;
  background: #3279fa;
  width: 30px !important;
  height: 30px;
  color: #ffffff !important;
  border-radius: 500px;
  font-weight: bold;
  padding: 0px 6px !important;
  margin: -125px 20px;
  box-shadow: 1px 1px 4px 0px rgb(5 17 27 / 59%);
}

.alice-carousel__prev-btn-item, .alice-carousel__next-btn-item{
  color: #ffffff !important;
}

.alice-carousel__next-btn{
  right: -5px;
}

.alice-carousel__prev-btn-item:hover, .alice-carousel__next-btn-item:hover {
  color:#ffffff !important;
}

.lang_change_btn_grp{
  display: flex;
  justify-content: center;
  align-items: center;
}