
@keyframes slideRight {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fadeFromRight {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  20% {
    opacity: 0;
    transform: translateX(10px);
  }
  50% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes revealRight {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@mixin blinking($color,$time) {
  @keyframes blink-anim {
    0% {
      box-shadow: 0 0 0 $color;
    }
    50% {
      box-shadow: 0 0 0 1rem transparent;
    }
  }
  animation: blink-anim $time infinite;
}
@keyframes popup {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes popdown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes swirl {
  0% {
    opacity: 0;
    transform: rotate(5deg) translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes mergeOut {
  0% {
    transform: scale(0);
  }
  // 80% {
  //   transform: scale(1.1);
  // }
  100% {
    transform: scale(1);
  }
}
@keyframes lightUp {
  0% {
    box-shadow: 0 0 0 #fff;
  }
  50% {
    box-shadow: 0 0 0 2rem transparent;
  }
}
@keyframes blink {
  0% {
    box-shadow: 0 0 0 $blink-color;
  }
  50% {
    box-shadow: 0 0 0 1rem transparent;
  }
}
@keyframes blinkIndicator {
  0% {
    box-shadow: 0 0 0 #2962ff;
  }
  50% {
    box-shadow: 0 0 0 1rem transparent;
  }
}
@keyframes bounceReveal {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  10% {
    opacity: 0;
    transform: translateY(-10px);
  }
  50% {
    opacity: 0;
    transform: translateY(10px);
  }
  70% {
    opacity: 1;
    transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes clipReveal {
  0% {
    clip-path: circle(0% at 50% 50%);
  }
  100% {
    clip-path: circle(150% at 50% 50%);
  }
}
